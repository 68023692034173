




































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class General extends Vue {
  private nombreWeb: any = "";
  private aNoticiasCategoria: Array<any> = [];
  private nombreCategoria: any = "";
  private currentPage = 1;
  private total = 0;
  private win = window;

  @Watch("currentPage")
  private currentPageWatch(newValue: any) {
    if (newValue >= 0 && newValue <= Math.ceil(this.total / 9)) {
      this.getInfoNoticiaCategoria();
    }
  }

  private mounted() {
    if (
      this.$router.currentRoute.params.nombre &&
      this.$router.currentRoute.params.nombre != ""
    ) {
      this.nombreWeb = this.$router.currentRoute.params.nombre;
      this.getInfoNoticiaCategoria();
    }
  }

  private getInfoNoticiaCategoria() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get(
        "noticias/categoria?pagina=" +
          this.currentPage +
          "&nombre_web=" +
          this.nombreWeb
      )
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.aNoticiasCategoria = res.data.noticias;
        this.nombreCategoria = res.data.nombre;
        for (let i = 0; i < this.aNoticiasCategoria.length; i++) {
          if (this.aNoticiasCategoria[i].fotos.image_intro) {
            this.aNoticiasCategoria[i].fotos.image_intro =
              base + this.aNoticiasCategoria[i].fotos.image_intro;
          } else {
            this.aNoticiasCategoria[i].fotos.image_intro =
              base + "Noticias/default_preview.jpg";
          }
        }
        this.total = res.data.total;
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private fechaPublicacion(fechaInicio: any) {
    const anhoInicio = fechaInicio.substring(0, 4);
    let mesInicio: any = parseInt(fechaInicio.substring(5, 7), 10);
    const diaInicio = fechaInicio.substring(8, 10);

    if (mesInicio == 1) {
      mesInicio = "enero";
    } else if (mesInicio == 2) {
      mesInicio = "febrero";
    } else if (mesInicio == 3) {
      mesInicio = "marzo";
    } else if (mesInicio == 4) {
      mesInicio = "abril";
    } else if (mesInicio == 5) {
      mesInicio = "mayo";
    } else if (mesInicio == 6) {
      mesInicio = "junio";
    } else if (mesInicio == 7) {
      mesInicio = "julio";
    } else if (mesInicio == 8) {
      mesInicio = "agosto";
    } else if (mesInicio == 9) {
      mesInicio = "septiembre";
    } else if (mesInicio == 10) {
      mesInicio = "octubre";
    } else if (mesInicio == 11) {
      mesInicio = "noviembre";
    } else {
      mesInicio = "diciembre";
    }

    if (mesInicio != 0) {
      return diaInicio + " de " + mesInicio + " " + anhoInicio;
    }
  }
  private irNoticia(idNoticia: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Publicacion" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Publicacion",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  private irCategoria(idCategoria: any, nombreWeb: any) {
    if (
      this.$router.currentRoute.name == "Categoria" &&
      this.$router.currentRoute.params.nombre == nombreWeb
    ) {
      this.$router.go(0);
    } else {
      this.$router
        .push({
          name: "Categoria",
          params: { nombre: nombreWeb }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
}
